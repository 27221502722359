import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


const Platforms = ({data}) => {
    const pageContent = data.allWpPage.nodes[0];
    const pagePosts = data.allWpPlatform.nodes;
  
    return(
    <Layout>
        <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />
        
        <div className="page-intro page-inner">
            <div className="container">
                <div className="row">
                <div className="col-md-12 col-xl-9 col-xxl-11" data-aos="fade-up">
                    <h1 className="title mb-3 mb-md-4 pb-xl-2" dangerouslySetInnerHTML={{__html: pageContent?.platformPage.title}} />
                    <div dangerouslySetInnerHTML={{__html: pageContent?.content}} />
                </div>
                </div>
            </div>
        </div>


        <div className="container pt-md-0 pt-5">
            {pagePosts && pagePosts.map((platform, index) => (
                <div className="platform-item" data-aos="fade-up" key={index}>
                    <div className="row ">
                        <div className="col-md-7 col-sm-6 d-flex align-items-center justify-content-center mb-sm-0 mb-3">
                            <span className="platform-count">0{index + 1}</span>
                            <div className="platform-title">
                                <h6>{platform.platform.shortFormName}</h6>
                                <h2 dangerouslySetInnerHTML={{__html: platform.title}} />
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6 d-flex align-items-start justify-content-center flex-column">
                            <div dangerouslySetInnerHTML={{__html: platform.content && platform.content}} />
                            <p></p>
                            <Link aria-label="Platform" to={'/platform/' + platform.slug} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                                Read more

                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                </svg>
                                </Link>
                        </div>
                    </div>
                </div>
            ))}
            
        </div>
        

        
    
    </Layout>
    )
}

export default Platforms

export const query = graphql`{
    allWpPage(filter: {slug: {eq: "platform"}}) {
        nodes {
            seo {
                title
                metaDesc
                focuskw
              }
          content
          platformPage {
            title
          }
        }
    }
    allWpPlatform(sort: {fields: date, order: DESC}) {
        nodes {
          title
          slug
          platform {
            shortFormName
          }
          content
        }
    }
  }`